import * as THREE from "three";
import Experience from "../Experience.js";
import Environment from "./Environment.js";
import Floor from "./Floor.js";
import Stars from "./Stars.js";
import FireEvacuationSimulation from "./FireEvacuationSimulation.js";
//not sure why this is throwing an error, the above line should work
// import FireEvacuationSimulation from "./fireEvacuationSimulation.js";
import Callout from "./Callout.js";
import Interlocutor from "./Interlocutor.js";

export default class World {
  constructor() {
    this.experience = new Experience();
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.floor = new Floor();

    // Wait for resources
    this.resources.on("ready", () => {
      // Setup
      console.log("resources ready");
      this.stars = new Stars();

      this.fireEvacuationSimulation = new FireEvacuationSimulation();
      // this.callout = new Callout()
      this.environment = new Environment();
    });

    // this.dummyInterlocutor = new Interlocutor("Samir", 0, "grey");
    // dummyInterlocutor.position.set(0, 0, 0);
    // this.scene.add(this.dummyInterlocutor);
  }
  update() {
    // this.dummyInterlocutor.update();
  }
}
