import * as THREE from "three";
import Experience from "../Experience.js";
import Callout from "./Callout.js";

export default class FireEvacuationSimulation {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.geometry = new THREE.PlaneGeometry(1, 1);

    //TODO: Update this
    const invernessMapTexture = this.resources.items.invernessMap;
    invernessMapTexture.wrapS = THREE.RepeatWrapping;
    invernessMapTexture.wrapT = THREE.RepeatWrapping;
    invernessMapTexture.repeat.set(1, 1);

    this.invernessMapMaterial = new THREE.MeshBasicMaterial({
      map: invernessMapTexture,
    });

    this.invernessMap = new THREE.Mesh(
      this.geometry,
      this.invernessMapMaterial
    );
    this.invernessMap.rotation.x = -Math.PI / 2;

    this.invernessMap.position.y += 0.65;
    this.invernessMap.position.z -= 0.75;
    //scale invernessMap up 10
    this.invernessMap.scale.x = 10;
    this.invernessMap.scale.y = 10;
    this.invernessMap.scale.z = 10;

    this.scene.add(this.invernessMap);
  }
}
