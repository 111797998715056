export default [
  // {
  //     name: 'iceColorTexture',
  //     type: 'texture',
  //     path: 'textures/ice/Stylized_18_Ice_basecolor.jpg'
  // },
  // {
  //     name: 'iceHeightTexture',
  //     type: 'texture',
  //     path: 'textures/ice/Stylized_18_Ice_height.jpg'
  // },
  // {
  //     name: 'iceNormalTexture',
  //     type: 'texture',
  //     path: 'textures/ice/Stylized_18_Ice_normal.jpg'
  // },
  // {
  //     name: 'iceAmbientOcclusionTexture',
  //     type: 'texture',
  //     path: 'textures/ice/Stylized_18_Ice_ambientocclusion.jpg'
  // },
  // {
  //     name: 'iceMetalnessTexture',
  //     type: 'texture',
  //     path: 'textures/ice/Stylized_18_Ice_metallic.jpg'
  // },
  // {
  //     name: 'iceRoughnessTexture',
  //     type: 'texture',
  //     path: 'textures/ice/Stylized_18_Ice_roughness.jpg'
  // },
  // {
  //     name: 'star',
  //     type: 'texture',
  //     path: 'textures/5.png'
  // },
  // {
  //     name: 'sushiModel',
  //     type: 'gltfModel',
  //     path: 'models/sushi.glb'
  // } ,
  {
    name: "campusBuildingsModel",
    type: "gltfModel",
    path: "simulation/campusBuildings003.gltf",
  },
  // {
  //     name: 'wave1',
  //     type: 'texture',
  //     path: 'spectra/wave.png'
  // },
  // {
  //     name: 'spectrogram1',
  //     type: 'texture',
  //     path: 'spectra/spectrogram.jpg'
  // },
  {
    name: "font",
    type: "font",
    path: "helvetiker_regular.typeface.json",
  },
  {
    name: "campusMap",
    type: "texture",
    path: "simulation/ucbcampusmap.jpg",
  },
  {
    name: "invernessMap",
    type: "texture",
    path: "simulation/combined_image.jpg",
  },
];
