import * as THREE from "three";
import Experience from "./Experience.js";
import { XRControllerModelFactory } from "three/examples/jsm/webxr/XRControllerModelFactory.js";
import { Controller } from "lil-gui";

export default class Controllers {
  constructor() {
    this.experience = new Experience();
    this.canvas = this.experience.canvas;
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.renderer = this.experience.renderer;

    this.t_geom = new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 0, -1.0),
      new THREE.Vector3(0, 0, -1.0),
    ]);
    this.t_line = new THREE.LineSegments(this.t_geom);
    this.t_line.material.color = new THREE.Color("white");

    this.l_raycast_indicator = new THREE.Group();
    this.l_raycast_indicator.add(this.t_line.clone());

    this.r_raycast_indicator = new THREE.Group();
    this.r_raycast_indicator.add(this.t_line.clone());

    this.r_connection = false;
    this.l_connection = false;

    this.l_raycaster = new THREE.Raycaster();
    this.r_raycaster = new THREE.Raycaster();

    const onSelectStart = function () {
      console.log("trigger pressed!");
    };

    this.controller1 = this.renderer.instance.xr.getController(0);
    this.controller1.addEventListener("selectstart", onSelectStart);
    // this.controller1.addEventListener( 'selectend', onSelectEnd );

    this.controller2 = this.renderer.instance.xr.getController(1);
    this.controller2.addEventListener("selectstart", onSelectStart);

    //why is this here?
    this.controller1.gamepad = undefined;
    this.controller2.gamepad = undefined;

    // this.controller2.addEventListener( 'selectend', onSelectEnd );
    this.controller2.addEventListener("connected", (event) => {
      if (event.data.gamepad) {
        this.r_connection = true;
        this.controller2.gamepad = event.data.gamepad;
      }
    });

    this.controller1.addEventListener("connected", (event) => {
      if (event.data.gamepad) {
        this.l_connection = true;
        this.controller1.gamepad = event.data.gamepad;
      }
    });

    this.experience.cameraGroup.add(this.controller1);
    this.experience.cameraGroup.add(this.controller2);

    this.controllerModelFactory = new XRControllerModelFactory();

    this.controllerGrip1 = this.renderer.instance.xr.getControllerGrip(0);
    this.controllerGrip1.add(
      this.controllerModelFactory.createControllerModel(this.controllerGrip1)
    );
    this.experience.cameraGroup.add(this.controllerGrip1);

    this.controllerGrip2 = this.renderer.instance.xr.getControllerGrip(1);
    this.controllerGrip2.add(
      this.controllerModelFactory.createControllerModel(this.controllerGrip2)
    );
    this.experience.cameraGroup.add(this.controllerGrip2);

    this.controller1.add(this.l_raycast_indicator);
    this.controller2.add(this.r_raycast_indicator);

    this.intersected = [];
    this.tempMatrix = new THREE.Matrix4();
  }
  raycast(controller, raycaster) {
    controller.updateMatrixWorld();
    this.tempMatrix.identity().extractRotation(controller.matrixWorld);

    raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
    raycaster.ray.direction.set(0, 0, -1).applyMatrix4(this.tempMatrix);

    return raycaster.intersectObjects(this.experience.raycastables, false);
  }
  update() {
    if (this.r_connection) {
      this.raycast(this.controller2, this.r_raycaster);
      // this.controller2.updateMatrixWorld();
      // this.tempMatrix.identity().extractRotation( this.controller2.matrixWorld );

      // this.r_raycaster.ray.origin.setFromMatrixPosition( this.controller2.matrixWorld );
      // this.r_raycaster.ray.direction.set( 0, 0, -1 ).applyMatrix4( this.tempMatrix );

      // let intersects = this.r_raycaster.intersectObjects( this.experience.world.fireEvacuationSimulation.agents.children, false);
      // console.log(intersects)

      for (const agent of this.experience.world.fireEvacuationSimulation.agents
        .children) {
        agent.material.color.set("#ff0000");
      }
      if (intersects.length > 0) console.log(intersects);
      for (const intersect of intersects) {
        intersect.object.material.color.set("#0000ff");
      }

      // if controller2 thumbstick is pushed, do locomotion and tell others
      // update peers with right controller position

      // this.trigger_end()
      // this.trigger_start()
    }
    if (this.l_connection) {
      // this.trigger_end()
      // this.trigger_start()
    }
  }
  trigger_start() {
    if (this.controller2.gamepad) {
      if (this.controller2.gamepad.buttons[0].pressed) {
        this.controller2.add(this.r_raycast_indicator);
      }
    }
    if (this.controller1.gamepad) {
      if (this.controller1.gamepad.buttons[0].pressed) {
        this.controller1.add(this.l_raycast_indicator);
      }
    }
  }

  trigger_end() {
    if (this.controller2.gamepad) {
      if (!this.controller2.gamepad.buttons[0].pressed) {
        this.controller2.remove(this.r_raycast_indicator);
      }
    }
    if (this.controller1.gamepad) {
      if (!this.controller1.gamepad.buttons[0].pressed) {
        this.controller1.remove(this.l_raycast_indicator);
      }
    }
  }
}
